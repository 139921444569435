$blue: #007bff;
$my-blue: #29445a;
$sky-blue: #77b2d1;
$light-blue: #e2f1f8;

$dark-brown: #271b1a;
$brown: #aa7f7c;
$mid-brown: #c2aca4;
$light-brown: #f7ebe7;

// $indigo: #6610f2;
// $purple: #6f42c1;
// $pink: #e83e8c;
// $red: #dc3545;
$orange: #fd7e14;
$yellow: #ffe15c;

$green: #2d7d60;

// $teal: #20c997;
// $cyan: #17a2b8;

$theme-colors: (
  // "primary": $my-blue,
  // "secondary": $sky-blue,
  // "info": $light-blue,
  // "primary-brown": $brown,
  // "secondary-brown": $mid-brown,
  // "info-brown": $light-brown,
  // "dark-brown": $dark-brown,
  "my-warning": $orange
);

// $link-color: $blue;
// $link-decoration:                         none !default;
// $link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration: none;

$font-family-sans-serif: "Roboto Mono", Roboto, "Roboto Slab", "Helvetica Neue", Arial, "Noto Sans", -apple-system,
  BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-monospace: "Courier New", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", monospace;

@import "~bootstrap/scss/bootstrap.scss";

// handle chart tooltip flicker
// REF: https://github.com/google/google-visualization-issues/issues/2162#issuecomment-281413636
svg > g > g:last-child { pointer-events: none }
